import React, { useEffect, useState } from "react";
import AppToolbar from "../../Components/UI/AppToolbar/AppToolbar";
import Container from "../../Components/UI/Container/Container";
import PromoLogo from "../../assets/icons/promo_logo.png"
import "../MainPage/MainPage.css"
import About from "../../Components/MainPage/About/About";
import Equipment from "../../Components/MainPage/Equipment/Equipment";
import Footer from "../../Components/UI/Footer/Footer";
import Showcase from "../../Components/MainPage/Showcase/Showcase";
import { useDispatch, useSelector } from "react-redux";
import { getServers } from "../../Store/services/serversSlice";
const Admin = (props) => {
    const dispatch = useDispatch();
    const [company, setCompany] = useState("forrest");
    useEffect(()=>{
        dispatch(getServers(company));
    }, [dispatch, company])
    const servers = useSelector(state=>state.servers.servers)
    const {user} = useSelector(state=>state.users)
    return(
        <div className="MainPage">
            <div className="Promo__wrapper">
                <Showcase 
                    servers={servers}
                    setForrest = {()=>{setCompany("forrest")}}
                    setNimbus = {()=>{setCompany("nimbus")}}
                    company={company}
                    user={user}
                />
            </div>
        </div>
    )
}

export default Admin;