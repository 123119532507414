
import { useSelector } from 'react-redux';
import Routes from './Routes/Routes';


function App() {
	const {user} = useSelector(state => state.users);
	return (
    <>
		<main>
			<Routes user={user} />
		</main>
	</>
  	);
}

export default App;
