import React from "react";
import Container from "../../UI/Container/Container"
import AboutImg from "../../../assets/images/about.jpg"
import "./About.css"
const About = (props) => {
    return (
        <div id="about" className="About__wrapper">
            <div className="About">
                <Container>
                    <div className="About__inner">
                        <div className="About__inner_content">
                            <div className="About__title">About us</div>
                            <div className="About__subtitle">Leading IT and Cyber Security integrator</div>
                            <div className="About__text">
                                The best team of engineers to solve your Cyber Security and IT challenges
                                <br />We provide you with first line technical support 
                                <br />90% of your problems solved without vendor involvement Free product consulting support for the first year
                            </div>
                        </div>
                        <img className="About__img" src={AboutImg} alt = "About" />
                    </div>
                </Container>
            </div>
            
        </div>
    )
}

export default About;