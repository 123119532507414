import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../axiosApi";

const initialState = {
    servers: [],
    currentServer: []
};

export const getServers = createAsyncThunk(
    'servers/fetch',
    async (company) => {
        return await axios.get(`/servers/all/${company}`).then(res => res.data);
    }
)

export const updateServer = createAsyncThunk(
    'servers/fetch',
    async (payload, thunkApi) => {
        const res = await axios.post(`/servers/update/${payload.id}`, payload.data).then(res => res.data);
        payload.navigate("/admin")
        return res.data;
    }
)


export const deleteServer = createAsyncThunk(
    'servers/delete',
    async (id) => {
        const res = await axios.post('/servers/delete/'+id);
        const dp = initialState.servers.indexOf(res.data);
        console.log(dp);
        return dp;
    }
)
export const getServerById = createAsyncThunk(
    'servers/fetchByAuthor',
    async (id) => {
        return await axios.get('/servers/'+id).then(res => res.data);
    }
)

export const createServer = createAsyncThunk(
    'servers/create',
    async(payload, thunkApi) => {
        const res =  await axios.post('/servers', payload.data);
        payload.navigate("/admin");
        return res.data;

    }
)
const serversSlice = createSlice({
    name: 'galleryPosts',
    initialState,
    reducers: {
        setServer: (state, action)=> {
            state.currentServer = action.payload;
        },
    },
    extraReducers: {
        [getServers.fulfilled]: (state, action) => {
            state.servers = action.payload;
        },
        [createServer.fulfilled]: (state, action) => {
            state.currentServer = action.payload;
        },
        [getServerById.fulfilled]: (state, action) => {
            state.currentServer = action.payload;
        },
        [deleteServer.fulfilled]: (state, action) => {
            state.servers.splice(action.payload, 1);
        }
    }
})
export const {setServer} = serversSlice.actions;
export default serversSlice.reducer;