import React from "react";
import "./UpArrow.css";
import Arrow from "../../../assets/icons/downarrow.svg"
const UpArrow = (props) => {
    return (
        <div onClick={props.onClick} className="UpArrow">
            <img className="UpArrow__arrow" src={Arrow} alt="arrow"/>
        </div>
    )
}

export default UpArrow;