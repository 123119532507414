import React, { useEffect, useState } from "react";
import Container from "../../Components/UI/Container/Container";
import "../MainPage/MainPage.css"
import { useDispatch, useSelector } from "react-redux";
import { createServer, getServerById, getServers, setServer, updateServer } from "../../Store/services/serversSlice";
import FormInput from "../../Components/UI/Form/FormInput/FormInput";
import Select from 'react-select';
import { useNavigate, useParams } from "react-router-dom";
import "./AddServerPage.css"
import { nanoid } from "@reduxjs/toolkit";
import ButtonGreen from "../../Components/UI/Buttons/ButtonGreen/ButtonGreen";
import axios, { apiURL } from "../../axiosApi";
import FileInput from "../../Components/UI/Form/FileInput";
const AddServerPage = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
   
    const [serverState, setServerState] = useState ({
        title: "",
        image: "",  
        specifications: [
            {
                title: "",
                description: ""
            }
        ],
        description: "",
        company: ""
    })
    const options = [
        { value: 'forrest', label: 'forest' },
        { value: 'nimbus', label: 'nimbus' },
      ];
    const addSpec = () =>{
        const stateCopy = {...serverState};
        stateCopy.specifications.push({
            title: "",
            description: ""
        })
        setServerState(stateCopy)
    }
    const spliceSpec = (index) =>{
        const stateCopy = {...serverState};
        stateCopy.specifications.splice(index, 1)
        setServerState(stateCopy)
    }
    
    const createServe = async () => {
        const formData = new FormData();
        Object.keys(serverState).forEach(key => {
            if (typeof serverState[key] === 'object' && serverState[key] !== null) {
                formData.append(key, JSON.stringify(serverState[key]))
            } else {
                formData.append(key, serverState[key]);
            }
        });
        formData.append("image", serverState.image)
        await dispatch(createServer({
            data: formData,
            navigate
        }))
    }
    return(
        <div className="AddServerPage">
            <Container>
                <div className="Edit__title">
                    Редактировать сервер
                </div>
                <form className="Edit__form">
                    <FormInput
                        label="Название сервера"
                        value = {serverState?.title || ""}
                        required
                        onChangeHandler={(e)=>{
                            setServerState({...serverState, title: e.target.value})
                        }}
                    />

                    <FormInput
                        required
                        label="Описание сервера"
                        value = {serverState?.description || ""}
                        onChangeHandler={(e)=>{setServerState({...serverState, description: e.target.value})}}
                    />
                    <span>Компания</span>
                    <Select
                        className="Edit__select"
                        placeholder={"Название компании"}
                        options={options}
                        defaultValue = {serverState?.server}
                        onChange={(e)=>{
                            console.log(e);
                            setServerState({...serverState, company: e.value})
                            
                        }}
                    />
                    
                    <div className="Edit__title">Фото</div>
                    <FileInput
                        image={serverState.image}
                        title = "Изображение сервера"
                        onChange = {(e) => {
                            setServerState({...serverState, image: e.target.files[0]});
                        }}
                    />
                    <div className="Edit__title">Характеристики</div>
                    {
                        serverState?.specifications?.map((spec, index) => {
                            return(
                                <div key={index+nanoid} className="Add_spec">
                                    <div  className="Edit__spec">
                                        <FormInput 
                                            required
                                            placeholder="Название"
                                            value={spec.title}
                                            onChangeHandler={(e)=>{setServerState({...serverState}, serverState.specifications[index].title = e.target.value)}}
                                                
                                        />
                                        <FormInput 
                                            required
                                            placeholder="Описание"
                                            value={spec.description}
                                            onChangeHandler={(e)=>{setServerState({...serverState}, serverState.specifications[index].description = e.target.value)}}
                                        />
                                        
                                    </div>
                                    <button className="delete_spec" onClick={()=>{spliceSpec(index)}}>X</button>
                                </div>
                                
                            )
                        })
                    }
                    <button className="Add_spec_button" type="button" onClick={()=>addSpec()}>Добавить характеристику</button>
                    <ButtonGreen
                        type="button"
                        onClick = {()=>createServe()}
                    >
                        Добавить
                    </ButtonGreen>
                </form>
            </Container>
        </div>
    )
}

export default AddServerPage;