import React from "react";
import Container from "../../UI/Container/Container"
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import "./Footer.css"
const Footer = (props) => {

    const defaultState = {
        center: [24.848810, 55.068721],
        zoom: 10,
      };
    return (
        <div id="contacts" className={`Footer__wrapper ${props.colorful? "Footer__colorful" : ""}`}>
            <Container>
                <div className="Footer">
                    <h3 className="Footer__title">VMI EQUIPMENT SOFTWARE TRADING L.L.C </h3>
                    <div className="Footer__info">
                        <div className="Footer__info_text">
                            <p>Main office</p>
                            <div className="Footer__contacts">
                                <div className="Footer__contacts_text Footer__location">UAE, Dubai, Saih Shuaib 3, unit 14</div>
                                <div className="Footer__contacts_text Footer__phone">+971 58 158 6498</div>
                                <div className="Footer__contacts_text Footer__email">igor.khudomyassov@vmi.ae</div>
                            </div>
                        </div>
                        <div className="Footer__map">
                            <YMaps query={{ lang: 'en_RU' }}>
                                <Map defaultState={defaultState} width={"100%"}>
                                    <Placemark geometry={[24.848810, 55.068721]} />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Footer;