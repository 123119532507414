import React from "react";
import "./AppToolbar.css"
import HeaderLogo from "../../../assets/icons/vmi.svg";
import HeaderLogoDark from "../../../assets/icons/header_logo_dark.svg"
import Container from "../../UI/Container/Container"
import { useNavigate } from "react-router-dom";
const AppToolbar = (props) => {
    const navigate = useNavigate(); 
    const handleClickScroll = (elem) => {
        const element = document.getElementById(elem);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <div className="AppToolbar__wrapper">
            <Container>
                <div className="AppToolbar">
                    <img className="AppToolbar__logo" onClick={()=>{navigate("/")}} src = {props.theme==="white" ? HeaderLogo: props.theme==="dark"? HeaderLogo : null} alt="logo"/>

                    <nav className={`AppToolbar__navbar ${props.theme==="white" ? "dark_text": props.theme==="dark"?"white_text": ""}`}>
                        <div onClick={()=>{props.page==="main"?navigate("/") : navigate("/")}} className="AppToolbar__navbar_link">Home</div>
                        <div onClick={()=>{props.page==="main"?handleClickScroll("about") : navigate("/?scroll=about")}} className="AppToolbar__navbar_link">About us</div>
                        <div onClick={()=>{props.page==="main"?handleClickScroll("equipment"): navigate("/?scroll=equipment")}} className="AppToolbar__navbar_link">Equipment</div>
                        <div onClick={()=>{props.page==="main"?handleClickScroll("contacts"): navigate("/?scroll=contacts")}} className="AppToolbar__navbar_link">Contacts</div>
                    </nav>
                    <div className="AppToolbar__phone">+971 58 158 6498</div>
                </div>
                
            </Container>
            
        </div>
    )
}

export default AppToolbar;