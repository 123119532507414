import "./Loader.css"

const Loader = () => {

    return(
        <div className="Loader__wrapper">
            <div className="Loader__bg"></div>
            <span className="loader"></span>
        </div>
    )
}
export default Loader;