import React from 'react';
import {TextField, Grid, MenuItem} from '@mui/material';

const FormElement = (
    {
        name,
        select,
        required,
        label,
        value,
        onChange,
        multiline,
        rows,
        error,
        options,
        type,
    }
) => {
    let inputChildren = null;
    if(select) {
        inputChildren = options.map(option => (
            <MenuItem key={option._id} value={option._id}>
                {option.title}
            </MenuItem>
        ))
    }
    return (
        <Grid item xs={12}>
            <TextField
                value={value}
                type={type}
                select={select}
                helperText={error}
                error={!!error}
                onChange={onChange}
                required={required}
                name={name}
                variant='outlined'
                fullWidth
                rows={rows}
                label={label}
                multiline={multiline}
            >
                {inputChildren}
            </TextField>
        </Grid>
    )
}


export default FormElement;