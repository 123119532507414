import { nanoid } from "@reduxjs/toolkit";
import React from "react";
import Container from "../../UI/Container/Container";
import "./ServerSpec.css";
const ServerSpec = (props)=>{
    return(
        <div className="ServerSpec">
            <Container>
                <h3 className="ServerSpec__title">Key Features</h3>
                <div className="ServerSpec__details">
                    {
                        props.specs?.map(spec => {
                            return(
                                <div key = {spec.title} className="ServerSpec__details_item">
                                    <div className="ServerSpec__details_title">
                                        {spec.title}
                                    </div>
                                    <div className="ServerSpec__details_despriction">
                                        {spec.description}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
            
        </div>
    );
}

export default ServerSpec;