import React from "react";
import {Route, Routes as Routers} from 'react-router-dom'
import AddServerPage from "../Containers/AddServer/AddServerPage";
import Admin from "../Containers/Admin/Admin";
import Edit from "../Containers/Edit/Edit";
import Login from "../Containers/Login/Login";
import MainPage from "../Containers/MainPage/MainPage";
import ServerPage from "../Containers/ServerPage/ServerPage";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";

const Routes = (props) => {
    return (
        <Routers>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/server/:id" element={<ServerPage/>}/>
            <Route path='/login' element={(
                <ProtectedRoute
                    isAllowed={!props.user}
                    redirectedPath='/admin'
                >
                    <Login/>
                </ProtectedRoute>
            )}/>
            <Route path='/admin' element={(
                <ProtectedRoute
                    isAllowed={props.user}
                    redirectedPath='/login'
                >
                    <Admin/>
                </ProtectedRoute>
            )}/>
            <Route path='/edit/:id' element={(
                <ProtectedRoute
                    isAllowed={props.user}
                    redirectedPath='/login'
                >
                    <Edit/>
                </ProtectedRoute>
            )}/>
            <Route path='/addserver' element={(
                <ProtectedRoute
                    isAllowed={props.user}
                    redirectedPath='/login'
                >
                    <AddServerPage/>
                </ProtectedRoute>
            )}/>
        </Routers>
    )
}   

export default Routes;