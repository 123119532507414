import React from "react";
import "./ButtonFilledGreen.css"


const ButtonFilledGreen = (props) => {
    return (
        <button className="ButtonFilledGreen"
            type={props.type? props.type : "button"}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

export default ButtonFilledGreen;