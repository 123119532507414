import React from "react";
import "./ButtonGreen.css"


const ButtonGreen = (props) => {
    return (
        <button className="ButtonGreen"
            type={props.type? props.type : "button"}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

export default ButtonGreen;