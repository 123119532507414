import React, { useEffect } from "react";
import { apiURL } from "../../../axiosApi";
import Container from "../../UI/Container/Container";
import ShapesRight from "../../../assets/images/shapes_right.png"
import "./ServerInfo.css"
const ServerInfo = (props) => {
    return(
        <div className="ServerInfo__wrapper">
            <img className="ServerInfo__rightshapes" src={ShapesRight} alt="Shapes"/>
            <Container>
                <div className="ServerInfo">
                    
                    <h1 className="ServerInfo__title">{props.serverTitle}</h1>
                    <div className="ServerInfo__content">
                        <p className="ServerInfo__description" lang="ru">{props.serverDescription}</p>
                        <img className="ServerInfo__image" src={apiURL +"/uploads/" + props.serverImage} alt ={props.serverTitle} />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default ServerInfo;